import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {authGuard} from '@/guards/authGuard';

const Home = () => import(/* webpackChunkName: "login" */ '@/views/_public/home.component.vue');
const FAQ = () => import(/* webpackChunkName: "login" */ '@/views/_public/faq.component.vue');
const Support = () => import(/* webpackChunkName: "login" */ '@/views/_public/support.component.vue');
const Login = () => import(/* webpackChunkName: "login" */ '@/views/_public/login.component.vue');
const Verify = () => import(/* webpackChunkName: "verify" */ '@/views/_public/verify.component.vue');
const Layout = () => import(/* webpackChunkName: "layout" */ '@/views/_secure/layout/layout.component.vue');
const CoursesOverview = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/courses-overview.component.vue');
const VideoCourse = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/video-course.component.vue');
const ScormCourse = () => import(/* webpackChunkName: "scorm-course" */ '@/views/_secure/courses/scorm-course.component.vue');
const CourseSummary = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/course-summary.component.vue');
const LessonPlan = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/lesson-plans/lesson-plan.component.vue');
const MyProfile = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/account/my-profile.component.vue');
const MyCourses = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/account/my-courses.component.vue');
const MyLessonPlans = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/account/my-lesson-plans.component.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'Home',
        component: Home,
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ,
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/verify',
        name: 'Verify',
        component: Verify,
    },
    {
        path: '/secure',
        component: Layout,
        beforeEnter: authGuard,
        children: [
            {
                path: 'courses/overview',
                name: 'CoursesOverview',
                component: CoursesOverview,
            },
            {
                path: 'courses/video-course',
                name: 'VideoCourse',
                component: VideoCourse,
            },
            {
                path: 'courses/scorm-course',
                name: 'ScormCourse',
                component: ScormCourse,
            },
            {
                path: 'courses/course-summary',
                name: 'CourseSummary',
                component: CourseSummary,
            },
            {
                path: 'lesson-plan',
                name: 'LessonPlan',
                component: LessonPlan,
            },
            {
                path: 'account/profile',
                name: 'MyProfile',
                component: MyProfile,
            },
            {
                path: 'account/courses',
                name: 'MyCourses',
                component: MyCourses,
            },
            {
                path: 'account/lesson-plans',
                name: 'MyLessonPlans',
                component: MyLessonPlans,
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
